import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './thankYouPage.css';

const ThankYouPage = () => {
  const [params, setParams] = useState({});
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const paramsObj = Object.fromEntries(urlSearchParams.entries());
    setParams(paramsObj);
  }, []);

  useEffect(() => {
    if (params.pidx) {
      axios.patch(`${process.env.REACT_APP_API_URL}/donation/${params.pidx}`)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [params]);

  return (
    <div>
      <div className="error_messge">
        {params.message && (
          <div id="error-page">
            <div className="content">
              <h2 className="header" data-text="SORRY">
                SORRY
              </h2>
              <h4 data-text="Opps! Page not found">{params.message}</h4>

              <div className="btns">
                <a href="https://www.mwt.org.np/">return home</a>
                <a href="https://www.donation.mwt.org.np/">Donate Again</a>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="success_messge">
        {params.pidx && (
          <div id="error-page">
          <div className="content success-content">
            <h2 className="con-header" data-text="CONGRATULATION">
              CONGRATULATION
            </h2>
            <h4 data-text="Opps! Page not found">{params.message}</h4>
            <p>We at the Mithila Wildlife Trust, would like to extend our heartfelt gratitude for your generous donation towards our cause. Your contribution will go long way in helping us conserve nature and uplift people of Nepal.</p>
            <p>We recognize that your donation reflects your trust in our organization and our mission to protect and preserve biodiversity of our region through community-based conservation method. We promise to utilize your donation towards the most critical areas of our work, including wildlife conservation, community engagement and education.</p>
            <p>Once again, thank you for your unwavering support, and we look forward to your continued partnership in our efforts to safeguard the wildlife and their ecosystems and the community residing in the area.</p>
            <div className="btns">
              <a href="https://www.mwt.org.np/">return home</a>
              <a href="https://www.donation.mwt.org.np/">Donate Again</a>
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default ThankYouPage;

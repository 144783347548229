import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
import { LoginForm } from '../../sections/auth/login';
import DonateForm from '../../components/donation/DonateForm'

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 680,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(6, 0),
}));

// ----------------------------------------------------------------------

export default function DonatePageNew() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Donation </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection style={{backgroundColor:"#a4c4b5"}}>
            {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography> */}
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Container variant="h4" gutterBottom align='center'>
              <img src="/assets/logo/mwtlogo.png" alt=""  width={"225px"}/>
            </Container>

            <Typography variant="h4" sx={{ mb: 2 }} align='center'>
              Donate for a Cause
            </Typography>

            <DonateForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




// ----------------------------------------------------------------------

export default function LoginForm() {
  const [donationAmount, setDonationAmount] = useState("");
  const [userName, setUserName] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [userAddress, setUserAddress] = useState("")
  const [userPhone, setUserPhone] = useState("")
  const [userSuggestion, setUserSuggestion] = useState("")
  const [redirectUrl, setRedirectUrl] = useState("")
  const [orderId, setOrderId] = useState("")
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false)
  const DB_API_URL = `${process.env.REACT_APP_API_URL}/donation`;
  const navigate = useNavigate();

  useEffect(() => {
    const id = generateRandonId();
    setOrderId(id)
  }, [])

  const generateRandonId = () => {
    const randomId = Math.floor(Math.random() * 900000) + 100000;
    const id = randomId.toString()
    return (`MWT-${id}`);
  }

  const khaltiPayload = {
    "return_url": `${process.env.REACT_APP_SELF_URL}/thankyou`,
    "website_url": `${process.env.REACT_APP_SELF_URL}`,
    "amount": parseInt(donationAmount, 10) * 100,
    "purchase_order_id": orderId,
    "purchase_order_name": "MWTTest",
    "customer_info": {
      "name": userName,
      "email": userEmail,
      "phone": userPhone
    }
  };
  const databasePayload = {
    "donationId": orderId,
    "name": userName,
    "email":userEmail,
    "address": userAddress,
    "phoneNumber": userPhone,
    "amount": donationAmount,
    "suggestion": userSuggestion
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(orderId)

    // Validation
    const errors = {};
    if (!userName) {
      errors.userName = "Name is required";
    }
    if (!userAddress){
      errors.userAddress = "Address is required"
    }
    if (!userEmail) {
      errors.userEmail = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(userEmail)) {
      errors.userEmail = "Email is invalid";
    }
    if (!userPhone) {
      errors.userPhone = "Phone is required";
    }else if (!/^[0-9]{10}$/.test(userPhone)) {
      errors.userPhone = "Phone is invalid. Please enter a valid phone number.";
    }
    if (!donationAmount) {
      errors.donationAmount = "Donation amount is required";
    }else if (!/^\d+$/.test(donationAmount)) {
      errors.donationAmount = "Donation amount should be a positive number";
    } else if (parseInt(donationAmount, 10) < 9) {
      errors.donationAmount = "Donation amount should be a positive number and more than or equal to Rs.10";
    }

    if (Object.keys(errors).length === 0) {
      try {
        const newKhaltiPayload = {...khaltiPayload, purchase_order_id: orderId}
        console.log(newKhaltiPayload)
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/payment`, newKhaltiPayload);
        console.log(response.data)
        // setPidxId(response.data.pidx)
        if (response.status === 200) {
          try {
            const updatedDatabasePayload = { ...databasePayload, pidx: response.data.pidx, donationId: orderId };
            const user = await axios.post(DB_API_URL, updatedDatabasePayload);
  
            if(user.status === 200){
              setRedirectUrl(response.data.payment_url)
            }
            
          } catch (error) {
            console.log(error)
          }
        }
      } catch (error) {
        setLoading(false);
        toast.warning(error.response.data.customer_info.phone[0])
      }
    } else {
      setLoading(false);
      setErrors(errors);
      
      toast.warning(errors.userName)
      toast.warning(errors.userEmail)
      toast.warning(errors.userPhone)
      toast.warning(errors.userAddress)
      toast.warning(errors.donationAmount)
    }
  };

  if(redirectUrl){
    window.location.href = redirectUrl;
  }

  return (
    <>
    
      <Stack spacing={2} sx={{ my: 2 }}>
        <TextField name="name" label="Name" onChange={e => setUserName(e.target.value)} required/>
        <TextField name="email" label="Email address" value={userEmail || ""} onChange={e => setUserEmail(e.target.value)} required/>
        <TextField name="mobile" label="Mobile Number" value={userPhone || ""} onChange={e => setUserPhone(e.target.value)} required/>
        <TextField name="address" label="Address" value={userAddress || ""} onChange={e => setUserAddress(e.target.value)} required/>
        <TextField name="suggestion" label="Any Suggestion / Instruction" value={userSuggestion || ""} onChange={e => setUserSuggestion(e.target.value)}/>
        <TextField name="amount" label="Amount" value={donationAmount || ""} onChange={e => setDonationAmount(e.target.value)} required/>
      </Stack>
      {loading ? 
        <LoadingButton loading loadingPosition="start" fullWidth size="large" type="submit" variant="contained" disabled>
          Loading
        </LoadingButton>: 
        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleSubmit}>
        Donate
        </LoadingButton>
      }
      <ToastContainer />
      
    </>
  );
}
